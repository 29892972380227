import { memo, useEffect } from "react"
import { useLocation } from "react-router-dom"

const EIMZOClient = window.EIMZOClient
const EIMZO_MAJOR = 3
const EIMZO_MINOR = 27

if (EIMZOClient) {
  EIMZOClient.API_KEYS = [
    "localhost",
    "96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B",
    "business.vasit.uz",
    "66119F1799B709AE435EE2F0F38DD7B4CEA86744A7045C5D63B6EACD05FB6A36BA08212E15A1F7E671FD74DC25C8D86E37E5E8E45134D5CEBC0507BD33D6311E",
  ]
}

const ESPProvider = ({ setEcpKeys }) => {
  // ================CHECK VERSION==========================
  const checkVersion = () => {
    EIMZOClient.checkVersion(
      function (major, minor) {
        const newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR
        const installedVersion = parseInt(major) * 100 + parseInt(minor)

        if (installedVersion < newVersion) {
          // return dispatch(showAlert("Sizning E-IMZO dasturingiz versiyasi eskirgan, iltimos E-IMZO dasturini yangilang", 'error'))
        }

        EIMZOClient.installApiKeys(
          function () {
            loadKeys()
          },
          function (e, r) {
            if (r) {
              // dispatch(showAlert(r))
            } else {
              alert("ERROR")
            }
          }
        )
      },

      function (e, r) {
        if (r) {
          // dispatch(showAlert(r))
        } else {
          // dispatch(
          //   showAlert(
          //     "Sizda E-IMZO dasturi yoqilmagan, iltimos dasturni ishga tushiring"
          //   )
          // )
        }
      }
    )
  }

  // ==================LOAD KEYS=======================
  const loadKeys = () => {
    EIMZOClient.listAllUserKeys(
      function (o, i) {
        const itemId = "itm-" + o.serialNumber + "-" + i
        return itemId
      },

      function (itemId, keyData) {
        const newItem = checkExpire(itemId, keyData)
        return newItem
      },

      function (items, firstId) {
        // dispatch(espActions.setKeys(items));
        setEcpKeys(items)
      },

      function (e, r) {
        // dispatch(showAlert("E-IMZO dasturi topilmadi yoki ishga tushmagan"))
      }
    )
  }

  // ==================CHECK EXPIRE=================
  const checkExpire = (itemId, keyData) => {
    const now = new Date()
    keyData.expired = window.dates.compare(now, keyData.validTo) > 0
    keyData.itemId = itemId
    return keyData
  }

  useEffect(() => {
    if (!EIMZOClient) return null
    checkVersion()
  }, [])
  useEffect(() => {
    if (EIMZOClient) {
      loadKeys()
    }
  }, [EIMZOClient])
  return null
}

export default ESPProvider
