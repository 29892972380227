export const ProfileIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.5 11C14.7092 11 16.5 9.20914 16.5 7C16.5 4.79086 14.7092 3 12.5 3C10.2909 3 8.50002 4.79086 8.50002 7C8.50002 9.20914 10.2909 11 12.5 11Z"
        fill="#4060FF"
      />
      <path
        d="M21.2769 18.5558C22.0611 20.3494 20.4132 22 18.4557 22H6.54434C4.58688 22 2.93901 20.3494 3.72318 18.5559C4.2006 17.4639 4.90037 16.4718 5.78253 15.636C6.66468 14.8003 7.71195 14.1374 8.86455 13.6851C10.0171 13.2328 11.2525 13 12.5 13C13.7476 13 14.9829 13.2328 16.1355 13.6851C17.2881 14.1374 18.3354 14.8003 19.2176 15.636C20.0997 16.4718 20.7995 17.4639 21.2769 18.5558Z"
        fill="#4060FF"
      />
    </svg>
  )
}
