export const LogOutIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M5.20833 3.125C4.05774 3.125 3.125 4.05774 3.125 5.20833V19.7917C3.125 20.9423 4.05774 21.875 5.20833 21.875H12.5V15.625H7.29167C6.71637 15.625 6.25 15.1586 6.25 14.5833C6.25 14.008 6.71637 13.5417 7.29167 13.5417H18.3185L15.4093 16.4509C15.0025 16.8577 15.0025 17.5173 15.4093 17.9241C15.8161 18.3309 16.4756 18.3309 16.8824 17.9241L21.5699 13.2366C21.9767 12.8298 21.9767 12.1702 21.5699 11.7634L16.8824 7.07593C16.4756 6.66913 15.8161 6.66913 15.4093 7.07593C15.0025 7.48273 15.0025 8.14227 15.4093 8.54907L18.3185 11.4583H12.5V3.125H5.20833Z"
        fill="#C2C2CC"
      />
    </svg>
  )
}
