import React from "react"
import { BrowserRouter, Route } from "react-router-dom"
import Routes from "./routes/routes"
import Login from "./pages/Login"
import MainLayout from "./layout/MainLayout"
import { ConfigProvider } from "antd"

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#fff",
            headerColor: "#9898A6",
          },
        },
        token: {
          colorPrimary: "#4060FF",
          colorText: "#000000",
          borderRadius: 10,
          fontWeightStrong: 450,
          fontSize: 18,
          controlInteractiveSize: 20,
          // colorBorder: "#DEE0E3",
          // colorBorderSecondary: "#E9EAEC",
          // colorBgContainer: "#fff",
          // colorPrimaryActive: "#fff",
        },
      }}
    >
      <BrowserRouter>
        <Route path="/login" exact component={Login} />
        <MainLayout>
          <Routes />
        </MainLayout>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
