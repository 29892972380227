export const ProductIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M23.7607 13.2813L14.0625 3.58302C13.9179 3.43729 13.7459 3.32176 13.5562 3.24313C13.3666 3.1645 13.1633 3.12435 12.958 3.12501H3.90626C3.69906 3.12501 3.50034 3.20732 3.35383 3.35383C3.20732 3.50034 3.12501 3.69906 3.12501 3.90626V12.958C3.12435 13.1633 3.1645 13.3666 3.24313 13.5562C3.32176 13.7459 3.43729 13.9179 3.58302 14.0625L13.2813 23.7607C13.4264 23.9059 13.5986 24.021 13.7882 24.0995C13.9778 24.1781 14.181 24.2185 14.3862 24.2185C14.5915 24.2185 14.7947 24.1781 14.9843 24.0995C15.1739 24.021 15.3461 23.9059 15.4912 23.7607L23.7607 15.4912C23.9059 15.3461 24.021 15.1739 24.0995 14.9843C24.1781 14.7947 24.2185 14.5915 24.2185 14.3862C24.2185 14.181 24.1781 13.9778 24.0995 13.7882C24.021 13.5986 23.9059 13.4264 23.7607 13.2813ZM8.20313 9.37501C7.97136 9.37501 7.74479 9.30628 7.55207 9.17751C7.35936 9.04874 7.20916 8.86572 7.12046 8.65159C7.03176 8.43746 7.00856 8.20183 7.05377 7.97451C7.09899 7.74719 7.2106 7.53838 7.37449 7.37449C7.53838 7.2106 7.74719 7.09899 7.97451 7.05377C8.20183 7.00856 8.43746 7.03176 8.65159 7.12046C8.86572 7.20916 9.04874 7.35936 9.17751 7.55207C9.30628 7.74479 9.37501 7.97136 9.37501 8.20313C9.37501 8.51393 9.25154 8.812 9.03177 9.03177C8.812 9.25154 8.51393 9.37501 8.20313 9.37501Z"
        fill="#4060FF"
      />
    </svg>
  )
}
