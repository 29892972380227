import React, { useEffect, useState } from "react"
import { Form, Input, Button, Select } from "antd"
import { useHistory, useLocation } from "react-router-dom"
import { requests } from "../utils/requests"
import EPSProvider from "../providers/EPSProvider"
import { buyerKey, sellerKey } from "../constants"
import { soliqRequests } from "../utils/soliqRequest"
import putSignature from "../utils/putSignature"

const { Option } = Select
function Login() {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [ecpKeys, setEcpKeys] = useState([])

  useEffect(() => {
    const tokens = location?.search?.split("?token=")[1]
    const token = tokens?.split("&")[0]
    const soliqToken = tokens?.split("&")[1].split("soliqToken=")[1]
    console.log(soliqToken)

    if (token) {
      setLoading(true)
      localStorage.setItem("token", token)
      localStorage.setItem("soliqToken", soliqToken)
      setLoading(false)
      history.push("/")
      document.location.reload()
    }
  }, [])

  const login = async (res) => {
    const selectedKey = JSON.parse(res?.inn)
    setLoading(true)
    try {
      const response = await requests.Auth.login({ inn: selectedKey.TIN })
      if (response?.data) {
        localStorage.setItem("token", response?.data?.token)
        localStorage.setItem("userData", JSON.stringify(response?.data) || {})
        const data = { tin: selectedKey.TIN, inn: selectedKey.TIN }
        const sign = await putSignature(data, selectedKey)
        const resSoliq = await soliqRequests.Soliq.login({ sign: sign })
        localStorage.setItem("soliqToken", resSoliq?.data?.token?.access_token)
        setLoading(false)
        history.push("/")
        document.location.reload()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onFinish = (values) => {
    login(values)
  }
  const statickey = () => {
    if (ecpKeys?.length) {
      return ecpKeys
    } else {
      return [buyerKey]
    }
  }
  return (
    <>
      <EPSProvider setEcpKeys={setEcpKeys} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          height: "100vh",
        }}
      >
        <h2 className="text-2xl flex gap-2 ">Vasit Seller</h2>
        <Form onFinish={onFinish} className="w-[400px]">
          <Form.Item
            name="inn"
            rules={[
              {
                required: true,
                message: "Please, select ecp!",
              },
            ]}
          >
            <Select size="large" className="min-h-[58px]">
              {statickey().map((item) => (
                <Option key={JSON.stringify(item)}>
                  <div className="flex flex-col">
                    <span className="!m-0 !p-0">{item.O}</span>
                    <span className="text-sm !m-0 !p-0">{item.CN}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              block
              htmlType="submit"
              className="login-form-button"
              size="large"
              loading={loading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
export default Login

const staticData = {
  access_token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vYXBpLnZhc2l0LnV6L2FwaS92MS9hZG1pbi9hdXRoL2xvZ2luIiwiaWF0IjoxNzE4MTEzMjIxLCJleHAiOjE3MTgxMTY4MjEsIm5iZiI6MTcxODExMzIyMSwianRpIjoiSFpkSDBFbTZWWlQ3djA3cyIsInN1YiI6IjEiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.AfAcVpFdkefN8xbHT6_CY7Kke4P2CTmeeGruRB5jSJk",
}
