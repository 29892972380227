import { lazy } from "react"
// ESLATMA PERMISSION BERILADIGAN JOYDA, RATHNING / DAN KEYINGI BIRINCHI QIYMATI PERMISION STRING ICHIDA BOLISHI SHART
const routes = [
  {
    path: "/",
    component: lazy(() => import("../pages/Dashboard")),
    meta: {
      title: "Corporate Profile",
      icon: "DashboardIcon",
      permission: "/dashboard",
    },
  },
  {
    path: "/rfp",
    component: lazy(() => import("../pages/Rfp")),
    meta: {
      title: "RFP’s",
      icon: "ShoppingOutlined",
      permission: "staff",
    },
    noSub: true,
    children: [
      {
        path: "/rfp/:id",
        component: lazy(() => import("../pages/Rfp/Details")),
      },
    ],
  },
  {
    path: "/order",
    component: lazy(() => import("../pages/Orders")),
    meta: {
      title: "Orders",
      icon: "ShoppingIcon",
      permission: "staff",
    },
    noSub: true,
    children: [
      {
        path: "/order/:id",
        component: lazy(() => import("../pages/Orders/Details")),
      },
    ],
  },
  {
    path: "/products",
    component: lazy(() => import("../pages/Products")),
    meta: {
      title: "Products",
      icon: "ProductIcon",
      permission: "staff",
    },
    noSub: true,

    children: [
      {
        path: "/products/:id",
        component: lazy(() => import("../pages/Products/Details")),
      },
    ],
  },
  // {
  //   path: "/documents",
  //   component: lazy(() => import("../pages/Documents")),
  //   meta: {
  //     title: "Documents",
  //     icon: "FileOutlined",
  //     permission: "staff",
  //   },
  
  // },
  {
    path: "/warehouse",
    component: lazy(() => import("../pages/Settings/Warehouse")),
    meta: {
      title: "Settings",
      icon: "SettingOutlined",
      permission: "",
    },
    children: [
      {
        path: "/warehouse",
        component: lazy(() => import("../pages/Settings/Warehouse")),
        meta: {
          title: "Warehouse",
          icon: "",
          permission: "/seller/warehouse/index",
        },
      },
     
    ],
  },
]

export default routes
