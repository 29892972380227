export const language = [
  { key: "en", label: "Eng" },
  { key: "ru", label: "Ru" },
  { key: "uz", label: "Uz" },
]

export const permission = [
  {
    key: "/admin/category/index",
    id: 1,
    name: " index",
  },
  {
    key: "/admin/category/create",
    id: 2,
    name: "create",
  },
  {
    key: "/admin/category/update",
    id: 3,
    name: "update",
  },
  {
    key: "/admin/category/remove",
    id: 3,
    name: "remove",
  },
  {
    key: "/admin/unit/index",
    id: 1,
    name: "index",
  },
  {
    key: "/admin/unit/create",
    id: 2,
    name: "create",
  },
  {
    key: "/admin/unit/update",
    id: 3,
    name: "update",
  },
  {
    key: "/admin/unit/remove",
    id: 3,
    name: "remove",
  },
  {
    key: "/admin/attribute/index",
    id: 1,
    name: "index",
  },
]

export const soliqApi = "https://v3.soliqservis.uz:3443/api"

export const buyerKey = {
  disk: "/Volumes/DSKEYS/",
  path: "",
  name: "DS3102961330001",
  alias:
    "cn=xusanov saloxiddin to‘ychimurod o‘g‘li,name=saloxiddin,surname=xusanov,l=olmazor tumani,st=toshkent shahri,c=uz,o=media view mchj,uid=551865964,1.2.860.3.16.1.2=30411956780017,ou=кўрсатилмаган,t=direktor,1.2.860.3.16.1.1=310296133,businesscategory=masʼuliyati cheklangan jamiyat,serialnumber=77f153c0,validfrom=2023.03.07 14:51:11,validto=2025.03.07 23:59:59",
  serialNumber: "77F153C0",
  validFrom: "2023-03-07T09:51:11.000Z",
  validTo: "2025-03-07T18:59:59.000Z",
  CN: "XUSANOV SALOXIDDIN TO‘YCHIMUROD O‘G‘LI",
  TIN: "310296133",
  UID: "551865964",
  PINFL: "30411956780017",
  O: "MEDIA VIEW MCHJ",
  T: "DIREKTOR",
  type: "pfx",
  expired: false,
  itemId: "itm-77F153C0-1",
}

export const sellerKey = {
  disk: "/Volumes/DSKEYS/",
  path: "",
  name: "DS3110536560001 INTOP PLANET",
  alias:
    "cn=mirxodjayev davron mirafzalovich,name=davron,surname=mirxodjayev,l=olmazor tumani,st=toshkent shahri,c=uz,o=intop planet mchj,uid=476970476,1.2.860.3.16.1.2=32612790270038,ou=кўрсатилмаган,t=direktor,1.2.860.3.16.1.1=311053656,businesscategory=masʼuliyati cheklangan jamiyat,serialnumber=783de0e2,validfrom=2024.01.23 12:24:24,validto=2026.01.23 23:59:59",
  serialNumber: "783DE0E2",
  validFrom: "2024-01-23T07:24:24.000Z",
  validTo: "2026-01-23T18:59:59.000Z",
  CN: "MIRXODJAYEV DAVRON MIRAFZALOVICH",
  TIN: "311053656",
  UID: "476970476",
  PINFL: "32612790270038",
  O: "INTOP PLANET MCHJ",
  T: "DIREKTOR",
  type: "pfx",
  expired: false,
  itemId: "itm-783DE0E2-4",
}

export const products = [
  {
    id: 1,
    name: "Washbasin",
    attributes: [
      {
        id: 1,
        name: "Width",
        value: "45 cm",
      },
      {
        id: 1,
        name: "Height",
        value: "45 cm",
      },
      {
        id: 1,
        name: "Depth",
        value: "45 cm",
      },
    ],
    slug: "home-123234",
    sku: "123234",
    price: 150000,
    discount: 30,
    in_stock: true,
    expected_delivery_date: "Jan 14, 2024",
    brand: {
      id: 1,
      slug: "apple",
      name: "Apple",
    },
    images: [
      {
        id: 1,
        link: "/images/product_img.svg",
        position: 1,
      },
      {
        id: 2,
        link: "/images/product_img.svg",
        position: 2,
      },
    ],
    carts: [
      {
        id: 1,
        quantity: 1,
      },
    ],
  },
]
export const order2 = [
  {
      "id": 1,
      "status": {
          "key": 1,
          "value": "rfp"
      },
      "total_net": 37213800,
      "total_price": 37213800,
      "coupon_price": null,
      "comment": "adfds",
      "branch": "JKT Company 177 Kent St Brooklyn, NY 11222 United States",
      "order_items": [
          {
              "id": 1,
              "product": {
                  "id": 93,
                  "name": "Iphone 15 red, 128",
                  "images": [
                      {
                          "id": 56,
                          "link": "https://minio.vasit.uz/vasit/uploads/products/7h545SVWWGj4oVNPkFkPoP62jb8VIkvwq6ZKm9YF.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=c3fd7b85792d36a7213b319169eff4ce94a4307bd9cf2df85338ceaa37d0a8e2",
                          "position": 0
                      },
                      {
                          "id": 57,
                          "link": "https://minio.vasit.uz/vasit/uploads/products/K4Cg5uFK9iAUVLP2VUxJqtMcNei1mPqrtsS1xaNj.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=6862c647328491de3f638d82c61607e05081d4e0dbaa4d337eef9e4885c198ef",
                          "position": 0
                      }
                  ]
              },
              "warehouse": null,
              "quantity": 1,
              "delivery_price": 250000,
              "price": 12500000,
              "discount": 0,
              "total_net": 12500000,
              "total_price": 12500000,
              "shipping_address_id": null,
              "billing_address_id": null,
              "payment_method": 1,
              "delivery_date": "2024-07-24 00:00:00",
              "comment": "adfds"
          },
          {
              "id": 2,
              "product": {
                  "id": 14,
                  "name": "element2 41, red",
                  "images": [
                    {
                      "id": 56,
                      "link": "https://minio.vasit.uz/vasit/uploads/products/7h545SVWWGj4oVNPkFkPoP62jb8VIkvwq6ZKm9YF.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=c3fd7b85792d36a7213b319169eff4ce94a4307bd9cf2df85338ceaa37d0a8e2",
                      "position": 0
                  },
                  ]
              },
              "warehouse": null,
              "quantity": 1,
              "delivery_price": 14276,
              "price": 713800,
              "discount": 0,
              "total_net": 713800,
              "total_price": 713800,
              "shipping_address_id": null,
              "billing_address_id": null,
              "payment_method": 1,
              "delivery_date": "2024-07-24 00:00:00",
              "comment": "adfds"
          },
          {
              "id": 3,
              "product": {
                  "id": 92,
                  "name": "Iphone 15 white, 128",
                  "images": [
                      {
                          "id": 54,
                          "link": "https://minio.vasit.uz/vasit/uploads/products/GKftWbrtIClk8OZ5MibOZMKJ4BwiMllYmVKnCS1I.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=6b8292e66b1b47fabbe91c0457983aa7db7735bf7cecd20edda8511c2082c437",
                          "position": 0
                      },
                      {
                          "id": 55,
                          "link": "https://minio.vasit.uz/vasit/uploads/products/GVHWkEuAk4i4mk9tvvHFQDFh8YqCzRXVjGq4B7DI.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=JqgfQP7w5XW234sda254fNbakGw6hgtFCwa%2F20240706%2Fuz-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240706T102454Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Signature=cb98a4eadd51d97d09a956e4c124e12c838079d766117e7da23e7e39cd2abd6c",
                          "position": 0
                      }
                  ]
              },
              "warehouse": null,
              "quantity": 2,
              "delivery_price": 480000,
              "price": 12000000,
              "discount": 0,
              "total_net": 24000000,
              "total_price": 24000000,
              "shipping_address_id": null,
              "billing_address_id": null,
              "payment_method": 1,
              "delivery_date": "2024-07-24 00:00:00",
              "comment": "adfds"
          }
      ]
  }
]
export const factura = {
  Version: 1,
  FacturaType: 3,
  FacturaInvestmentObjectDoc: {
    ObjectId: "",
    ObjectName: "",
  },
  SingleSidedType: 0,
  HasMarking: false,
  FacturaId: "655c9a6c76fff90b966f3307",
  ItemReleasedDoc: {
    ItemReleasedTin: "",
    ItemReleasedFio: "",
    ItemReleasedPinfl: "",
  },
  IncomeType: 0,
  LotId: "",
  Notes: "",
  FacturaDoc: {
    FacturaNo: "TEST",
    FacturaDate: "2023-11-21",
  },
  ContractDoc: {
    ContractNo: "TEST",
    ContractDate: "2023-11-01",
  },
  FacturaEmpowermentDoc: {
    EmpowermentNo: "",
    EmpowermentDateOfIssue: "",
    AgentFacturaId: "655c9a6cbb5ff1054c8d0a50",
    AgentFio: "",
    AgentPinfl: "",
  },
  SellerTin: "200523221",
  BuyerTin: "203335595",
  OldFacturaDoc: {
    OldFacturaId: "",
    OldFacturaNo: "",
    OldFacturaDate: "",
  },
  ContractId: "",
  WaybillId: "",
  WaybillIds: [],
  Seller: {
    Name: "4-SON QURILISH TRESTI",
    Account: "20210000100176992001",
    BankId: "00425",
    Address: "Toshkent shahri Mirzo Ulug'bek tumani Xodjayeva 2a  ",
    Mobile: "",
    WorkPhone: "",
    Oked: "",
    DistrictId: "",
    Director: "RAXIMBEKOV RUSTAMBEK RAXMANBEKOVICH",
    Accountant: "RAXIMBEKOV RUSTAMBEK RAXMANBEKOVICH",
    VatRegCode: "326030178001",
    VatRegStatus: 20,
  },
  Buyer: {
    Name: "YUNFARKAM MCHJ",
    Account: "20208000404068900001",
    BankId: "00836",
    Address: "Toshkent shahri Yunusobod tumani YUNUSOBOD-15  ",
    Mobile: "",
    WorkPhone: "",
    Oked: "",
    DistrictId: "",
    Director: "MUXAMMADOV NODIR KARIMOVICH",
    Accountant: "XVAN TATYANA ROMANOVNA",
    VatRegCode: null,
    VatRegStatus: null,
    BranchName: "",
    BranchCode: "",
  },
  ForeignCompany: {
    CountryId: "",
    Name: "",
    Address: "",
    Bank: "",
    Account: "",
  },
  ProductList: {
    FacturaProductId: "655c9a6c76fff90b966f3308",
    HideReportCommittent: false,
    Tin: "200523221",
    HasExcise: false,
    HasVat: true,
    HasCommittent: false,
    HasLgota: false,
    HasMedical: false,
    HasCustom: false,
    Products: [
      {
        OrdNo: 1,
        CommittentName: "",
        CommittentTin: "",
        CommittentVatRegCode: "",
        CommittentVatRegStatus: null,
        CatalogCode: "01517001001003004",
        CatalogName:
          "Ўсимлик маргарини Masland Қаттиқ маргарин ёғлилиги 82%, (Каширланган фольга) 200 г.",
        BarCode: "",
        Name: "TEST",
        Serial: "",
        MeasureId: "",
        BaseSumma: 0,
        ProfitRate: 0,
        Summa: 9504.12,
        Count: 123.54,
        DeliverySum: 1174167,
        ExciseSum: 0,
        ExciseRate: 0,
        VatRate: 12,
        VatSum: 140900.1,
        DeliverySumWithVat: 1315067.6,
        WithoutVat: false,
        PackageCode: "1509126",
        PackageName: "шт. (упаковка) 200 грамм",
        ExchangeInfo: null,
        Marks: null,
        Origin: 4,
        LgotaId: null,
        LgotaName: null,
        LgotaType: null,
        LgotaVatSum: 0,
        WarehouseId: null,
        WarehouseName: null,
        WarehouseAddress: null,
        CountKilometer: 0,
        CountTon: 0,
      },
    ],
  },
}

export const contract = {
  Clients: [
    {
      BranchCode: "",
      Host: "",
      Name: "",
      No: 0,
      Time: "",
      Tin: "",
    },
  ],
  Contract: {
    Clients: [
      {
        Account: "",
        Address: "",
        BankId: "",
        BankName: "",
        BranchCode: "",
        BranchName: "",
        Fax: "",
        Fio: "",
        FizTin: "",
        Mobile: "",
        Name: "",
        Oked: "",
        Position: "",
        Role: "",
        Status: true,
        Tin: "",
        WorkPhone: "",
      },
    ],
    ContractDoc: {
      ContractDate: "01.05.2024",
      ContractExpireDate: "31.12.2024",
      ContractName: "Oldi-sotdi shartnoma",
      ContractNo: "001",
      ContractPlace: "VASIT ONLINE",
    },
    ContractId: "",
    CreatedOperator: "",
    HasVat: true,
    Owner: {
      Account: "",
      Address: "",
      BankId: "",
      BankName: "",
      BranchCode: "",
      BranchName: "",
      Fax: "",
      Fio: "",
      FizTin: "",
      Mobile: "",
      Name: "",
      Oked: "",
      Position: "",
      Role: "",
      Status: true,
      Tin: "",
      WorkPhone: "",
    },
    Parts: [
      {
        Body: "",
        OrdNo: "",
        Title: "",
      },
    ],
    Products: [
      {
        BarCode: "",
        CatalogCode: "",
        CatalogName: "",
        Count: 0,
        DeliverySum: 0,
        DeliverySumWithVat: 0,
        MeasureId: "",
        Name: "",
        OrdNo: "",
        PackageCode: 0,
        PackageName: "",
        Summa: 0,
        TotalSum: 0,
        VatRate: 0,
        VatSum: 0,
        WithoutVat: true,
      },
    ],
  },
  CreatedAt: "",
  ErrMsg: "",
  FileLink: "",
  HasAccessBranch: 0,
  OrganizationId: "",
  PageStatus: "",
  PdfUrlRu: "",
  PdfUrlUz: "",
  PngRu: "",
  PngUz: "",
  Seller: {
    BranchCode: "",
    Host: "",
    Name: "",
    No: 0,
    Time: "",
    Tin: "",
  },
  Sign: "",
  Status: "",
  UpdatedAt: "",
}

export const columsSoliqProduct = [
  {
    title: "Tovarning identifikatsiya kodi*",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Tovar (xizmat) nomi*",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Tovar (xizmat)ning shtrix kodi",
    dataIndex: "BarCode",
    key: "BarCode",
  },
  {
    title: "O'lchov birligi	Soni",
    dataIndex: "MeasureId",
    key: "MeasureId",
  },
  {
    title: "Narxi*",
    dataIndex: "Summa",
    key: "Summa",
  },
  {
    title: "Yetkazish narxi*",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "QQS stavkasi, %",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "QQS umumiy qiymati*",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Yetkazish narxi QQS bilan*",
    dataIndex: "name",
    key: "name",
  },
]

export const attributes = {
  data: {
    id: 7,
    name: "trousers",
    attributes: [
      {
        id: 1,
        name: "size",
        units: [
          {
            id: 3,
            name: "Unit Franecki-Streich",
          },
          {
            id: 4,
            name: "Unit Cruickshank Group",
          },
        ],
        is_required: true,
        type: {
          key: 1,
          value: "color",
        },
        characteristics: [
          {
            id: 1,
            name: "white",
            code: "#FFFFFF",
          },
          {
            id: 7,
            name: "256",
            code: null,
          },
          {
            id: 6,
            name: "512",
            code: null,
          },
          {
            id: 5,
            name: "42",
            code: null,
          },
          {
            id: 4,
            name: "41",
            code: null,
          },
          {
            id: 2,
            name: "red",
            code: "#FF0000",
          },
        ],
      },
      {
        id: 2,
        name: "color",
        units: [],
        is_required: true,
        type: {
          key: 2,
          value: "string",
        },
        characteristics: [
          {
            id: 2,
            name: "red",
            code: "#FF0000",
          },
          {
            id: 3,
            name: "green",
            code: "#008000",
          },
          {
            id: 2,
            name: "red",
            code: "#FF0000",
          },
          {
            id: 1,
            name: "white",
            code: "#FFFFFF",
          },
        ],
      },
      {
        id: 3,
        name: "ram",
        units: [
          {
            id: 2,
            name: "Unit Stoltenberg-Greenholt",
          },
        ],
        is_required: true,
        type: {
          key: 3,
          value: "number",
        },
        characteristics: [
          {
            id: 8,
            name: "128",
            code: null,
          },
        ],
      },
    ],
  },
}
