import { soliqApi } from "../constants"

export const timestamper = (signatureHex, callback, fail) => {
  console.log("signatureHex", signatureHex)
  fetch(
    `${soliqApi}/provider/api/utils/timestamp?signatureHex=${signatureHex}`,
    {
      method: "GET",
    }
  ).then((r) => {
    r.json().then((data) => {
      callback(data.data)
    })
  })
}
