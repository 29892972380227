import { FileTextFilled, ShoppingFilled } from "@ant-design/icons"

import { SettingsIcon } from "./icons/SettingsIcon"
import { RFPIcon } from "./icons/RFPIcon"
import { ProductIcon } from "./icons/ProductIcon"
import { ProfileIcon } from "./icons/ProfileIcon"
function IconFinder(icon) {
  switch (icon) {
    case "DashboardIcon":
      return <ProfileIcon />
    case "ShoppingOutlined":
      return <RFPIcon />
    case "SettingOutlined":
      return <SettingsIcon />
    case "ProductIcon":
      return <ProductIcon />
    case "ShoppingIcon":
        return <ShoppingFilled style={{color: "#4060FF", fontSize: "25px"}}/>
    case "FileOutlined":
      return <FileTextFilled style={{color: "#4060FF", fontSize: "25px"}}/>
    default:
      break
  }
}
export default IconFinder
