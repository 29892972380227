export const RFPIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M20.8333 7.2915C22.5592 7.2915 23.9583 5.89239 23.9583 4.1665C23.9583 2.44061 22.5592 1.0415 20.8333 1.0415C19.1074 1.0415 17.7083 2.44061 17.7083 4.1665C17.7083 5.89239 19.1074 7.2915 20.8333 7.2915Z"
        fill="#4060FF"
      />
      <path
        d="M20.8333 9.37484C21.1901 9.37484 21.5384 9.33897 21.875 9.27065V14.5832C21.875 12.8573 20.4759 11.4582 18.75 11.4582C17.0241 11.4582 15.625 12.8573 15.625 14.5832V15.6248H14.5833C12.8574 15.6248 11.4583 17.0239 11.4583 18.7498C11.4583 20.4757 12.8574 21.8748 14.5833 21.8748H6.25C4.52411 21.8748 3.125 20.4757 3.125 18.7498V6.24984C3.125 4.52395 4.52411 3.12484 6.25 3.12484H15.7292C15.6609 3.46141 15.625 3.80977 15.625 4.1665C15.625 7.04299 17.9568 9.37484 20.8333 9.37484Z"
        fill="#4060FF"
      />
      <path
        d="M19.7917 14.5832C19.7917 14.0079 19.3253 13.5415 18.75 13.5415C18.1747 13.5415 17.7083 14.0079 17.7083 14.5832V17.7082H14.5833C14.008 17.7082 13.5417 18.1745 13.5417 18.7498C13.5417 19.3251 14.008 19.7915 14.5833 19.7915H17.7083V22.9165C17.7083 23.4918 18.1747 23.9582 18.75 23.9582C19.3253 23.9582 19.7917 23.4918 19.7917 22.9165V19.7915H22.9167C23.492 19.7915 23.9583 19.3251 23.9583 18.7498C23.9583 18.1745 23.492 17.7082 22.9167 17.7082H19.7917V14.5832Z"
        fill="#4060FF"
      />
    </svg>
  )
}
